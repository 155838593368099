<template>
    <div>
        <div class="row">
            <div class="col-2"></div>
            <div class="col-10 d-flex justify-content-center align-items-center">
                <p class="mb-0 me-2">RELATIONS ({{ related.length }}) </p> <button class="btn btn-primary">Create</button>
            </div>
        </div>
        <div class="row" v-if="viewData">
            <div class="col-2"></div>
            <div class="col-2" v-for="(product, index) in related" :key="index">
                <div class="d-flex justify-content-between align-items-center">
                    <p class="mb-0">{{ product.id }} - Status: Published</p> <button
                        class="btn btn-sm btn-danger">X</button>
                </div>
                <div class="box-img">
                    <img :src="product.other_product.first_image.path" alt=""
                        v-if="product.other_product && product.other_product.first_image">
                </div>
                <div v-if="product">
                    <p>{{ product.other_product.title }}</p>
                </div>
                <div class="row">
                    <div class="col text-center">
                        <p>Moneys:</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6" v-if="product.other_product && product.other_product.moneys">
                        <span v-for="(money, index) in product.other_product.moneys" :key="index">
                            {{ index + ' | S/. ' + money.price }} <br>
                        </span>
                    </div>
                </div>
                <div class="row">
                    <button class="btn btn-primary">Edit</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Relations',
    props: ['data','viewData'],
    data() {
        return {
            related: this.data
        }
    },
    methods: {

    },

}
</script>
<style></style>