<template>
    <div>
        <h2>Products view</h2>
        <ProductsView />
    </div>
</template>
<script>
import axios from "axios";
import ProductsView from '@/components/products/view/index.vue'

export default {
    components: {
        ProductsView
    },
  data () {
    return {
        products:[]
    }
  },
  mounted() {
    
    },
  methods: {
    getProductView() {
            const url = this.appDomainApi + "shops/products/view/index";
            axios.get(url,)
                .then(res => {
                    console.log(res.data)
                    this.products = res.data
                })
                .catch(err => {
                    console.error(err);
                })
        },
  },
    
}
</script>

<style scoped>
    
</style>