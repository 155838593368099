<template>
    <div>
        <div class="row">
            <div class="col-2"></div>
            <div class="col-10 d-flex justify-content-center align-items-center">
                <p class="mb-0 me-2">PROMOTIONS ({{ promotions.length }}) </p>
                <button class="btn btn-primary">Create</button>
            </div>
        </div>
        <div v-if="viewData">
            <div v-for="(group, index) in productPromotions(promotions)" :key="index">
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-10 d-flex justify-content-left align-items-center">
                        <p class="mb-0 me-2">{{ index+1 }} - PROMOTION ({{ group.promotions.length }}) - CODE: {{ group.slug }}</p> 
                        <button
                            class="btn btn-primary">Add Promotion</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-2" v-for="(promotion, index) in group.promotions" :key="index">
                        <div class="d-flex justify-content-between align-items-center">
                            <p class="mb-0">Status: {{ promotion.status }}</p> 
                            <button class="btn btn-sm btn-danger" @click="promotionDelete(promotion.id)">X</button>
                        </div>
                        <div class="box-img">
                            <img :src="promotion.other_product.first_image.path" alt="">
                        </div>
                        <div class="row">
                            <div class="col text-center">
                                <p>Moneys:</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div v-if="promotion.other_product && promotion.other_product.moneys">
                                    <span v-for="(money, index) in promotion.other_product.moneys" :key="index">
                                        {{ index + ' | S/. ' + money.price }} <br>
                                    </span>
                                </div>
                            </div>
                            <div class="col-6 text-center">
                                <p>
                                    <b>P. Final: <br>
                                        {{ 'S/. ' + promotion.price }}</b>
                                </p>
                                <p v-if="promotion.other_product && promotion.other_product.moneys">
                                    <b>Descuento: <br>
                                        {{ 'S/. ' + (promotion.other_product.moneys[1].price - promotion.price) }}</b>
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <button class="btn btn-primary">Edit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name:'Promotions',
    props: ['data','viewData'],
    data() {
        return {
            promotions: this.data
        }
    },

    methods: {
        productPromotions(promotions) {

            console.log("productPromotions")
            // console.log(promotions)
            if (!Array.isArray(promotions)) {
                return []; // Retorna un array vacío si no es un array válido
            }
            if (promotions.length > 0) {
                const groupedPromotions = {};
                promotions.forEach((promotion) => {
                    console.log("promotion.slug: " + promotion.slug)
                    if (!groupedPromotions[promotion.slug]) {
                        console.log("promotion.slug - create: " + promotion.slug)

                        groupedPromotions[promotion.slug] = [];
                    }
                    groupedPromotions[promotion.slug].push(promotion);
                });
                // return {
                //     ...promotions,
                //     group: groupedPromotions, // Agrupar por slug dentro del producto
                // };
                console.log(Object.values(groupedPromotions).flat())
                // Retorna un array de promociones agrupadas por slug
                // return Object.values(groupedPromotions).flat();

                // Convierte el objeto agrupado en un array de arrays
                const data = Object.keys(groupedPromotions).map(slug => ({
                    slug,
                    promotions: groupedPromotions[slug], // Array de promociones agrupadas
                }));
                console.log("data----")
                console.log(data)
                return data;
            }
        },
        promotionDelete(item_id){
            console.log("promotionDelete: "+item_id)

            const url = this.appDomainApi + "shops/product/promotion/delete/" + item_id;

            axios.delete(url)
                .then(response => {
                    console.log(response.data);
                    this.promotions = [];
                    this.promotions = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
    },

}
</script>

<style>
.box-img {
    width: 10rem;
}

.box-img img {
    width: 100%;
}
</style>