<template>
    <div>
        <!-- CHECKBOX -->
        <div class="row">
            <div class="col">
                <div class="form-check form-switch" @change="updateUrlParams">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckCheckedDomains"
                        v-model="shopParent.packs">
                    <label class="form-check-label" for="flexSwitchCheckCheckedDomains">Packs</label>
                </div>
                <div class="form-check form-switch" @change="updateUrlParams">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckCheckedFromShop"
                        v-model="shopParent.relations">
                    <label class="form-check-label" for="flexSwitchCheckCheckedFromShop">Relations</label>
                </div>
                <div class="form-check form-switch" @change="updateUrlParams">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckCheckedFromAds"
                        v-model="shopParent.promotions">
                    <label class="form-check-label" for="flexSwitchCheckCheckedFromAds">Promotions</label>
                </div>
            </div>
        </div>

        <div v-for="product in products" :key="product.id">
            <!-- PRODUCT -->
            <div class="row">
                <div class="col-2">
                    <div class="box-img">
                        <img :src="product.first_image.path" alt="">
                    </div>
                </div>
                <div class="col-8">
                    <p>{{ product.id }} | {{ product.title }}</p>
                    <p>Moneys:</p>
                    <div v-if="product.moneys">
                        <span v-for="(money, index) in product.moneys" :key="index">
                            {{ index + ' | S/. ' + money.price }} <br>
                        </span>
                    </div>

                </div>
                <div class="col-2">
                    <router-link :to="'/product/edit/' + product.id" target="_blank"
                        class="btn btn-primary">Editar</router-link>
                    <button>View</button>
                </div>
            </div>
            <!-- PACKS -->
            <div>
                <Packs :data="product.packs" :viewData="shopParent.packs" />
            </div>

            <!-- PROMOTIONS -->
            <div>
                <Promotions :data="product.promotions" :viewData="shopParent.promotions" />
            </div>

            <!-- RELATIONS -->
            <div>
                <Relations :data="product.related_new" :viewData="shopParent.relations" />
            </div>
            <hr>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Promotions from '@/components/products/view/promotions.vue';
import Relations from '@/components/products/view/relations.vue';
import Packs from '@/components/products/view/packs.vue';

export default {
    components: {
        Promotions,
        Relations,
        Packs,
    },
    data() {
        return {
            products: [],
            shopParent: {
                packs: false,
                relations: false,
                promotions: false,
            }
        }
    },
    mounted() {
        this.getProductView()
        this.initParamsFromUrl()
    },
    computed: {
        urlWithParams() {
            const baseUrl = '/products/view'; // Reemplaza con tu URL base
            const queryParams = [];

            // Agrega cada parámetro si su checkbox está seleccionado
            if (this.shopParent.packs) {
                queryParams.push('packs=true'); // Reemplaza 'param1' y 'value1' con tus valores
            }
            if (this.shopParent.relations) {
                queryParams.push('relations=true'); // Reemplaza 'param1' y 'value1' con tus valores
            }
            if (this.shopParent.promotions) {
                queryParams.push('promotions=true'); // Reemplaza 'param2' y 'value2' con tus valores
            }

            
            // // Agrega los parámetros de paginación
            // queryParams.push(`page=${this.currentPage}`);

            // Combina la URL base con los parámetros
            return baseUrl + (queryParams.length > 0 ? '?' + queryParams.join('&') : '');
        },
    },
    methods: {
        getProductView() {
            const url = this.appDomainApi + "shops/products/view/index";
            axios.get(url,)
                .then(res => {
                    console.log(res.data)
                    this.products = res.data
                })
                .catch(err => {
                    console.error(err);
                })
        },
        updateUrlParams() {
            console.log("updateUrlParams")

            // Actualiza la URL cuando cambia el estado de los checkboxes
            window.history.replaceState({}, '', this.urlWithParams);
        },
        initParamsFromUrl() {
            console.log("initParamsFromUrl")
            const urlParams = new URLSearchParams(window.location.search);

            // Obtener los parámetros de los checkboxes
            this.shopParent.packs = urlParams.has('packs');
            this.shopParent.relations = urlParams.has('relations');
            this.shopParent.promotions = urlParams.has('promotions');


            // // Obtener los parámetros de paginación
            // const page = parseInt(urlParams.get('page')) || 1;
            // const pageSize = parseInt(urlParams.get('pageSize')) || 10;
            // this.currentPage = page;
            // this.pageSize = pageSize;


        },
    },

}
</script>

<style scoped></style>